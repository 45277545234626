import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Service Workies Ch3 — Control",
  "date": "2019-04-30",
  "promo": "serviceworkies",
  "description": "Intercepting Requests with Service Worker",
  "color": "#c03744"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` chapter 3 is released and ready to play!`}</p>
    <p>{`Up to this point in the game you've learned about the Service Worker lifecycle and how to react to it. With that foundation in place now in chapter three you'll learn one of the most powerful things a Service Worker can do — intercept http requests.`}</p>
    <h2>{`Intercept`}</h2>
    <p>{`A Service Worker, once installed and activated, sits in between your web page and the network. It can see every single request from the Page its installed on using its `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` event listener. With that powerful hook you can decide what you want to do with each request. Using `}<inlineCode parentName="p">{`respondWith`}</inlineCode>{` you can send back anything you want, including something from a cache.`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/intercept.gif",
        "alt": "intercepting requests"
      }}></img></p>
    <h2>{`Control`}</h2>
    <p>{`Service Workers can be stopped at any time. Including when you close a browser tab — which is what closing the Portal in game is simulating. The browser tab closes but its Service Workers sticks around, and is even still active — ready to intercept the very first request for the page the next time you revisit that site. This behavior is what allows a Service Worker to take full control of a site's entire network activity.`}</p>
    <p>{`I wanted to model this in game, giving you an even clearer mental model for when you build your own Service Workers. So now Kolohe turns silhouette when you close the Portal, and grapples into position before the Page code request if he's currently in control.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/kolohe-stopped.jpg",
        "alt": "stopped service worker"
      }}></img></p>
    <h2>{`Good Luck Have Fun`}</h2>
    <p>{`The release of this chapter took a minute to create, and had to wait for the big `}<a parentName="p" {...{
        "href": "/post/polish-makes-perfect/"
      }}>{`polish release`}</a>{` we shipped. But I think will be well worth the wait.`}</p>
    <p>{`Hope you have a blast while you learn. Happy hunting!`}</p>
    <Action link="https://serviceworkies.com" mdxType="Action">Play Now</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      